import themeStyles from '@styles/themes.module.css';

export const colorMap = {
  // Old colors
  white: 'var(--brand-color-white)',
  blue: 'var(--color-accent-default)',
  teal: 'var(--color-teal)',
  salmon: 'var(--color-salmon)',
  yellow: 'var(--color-yellow)',
  purple: 'var(--color-purple)',

  // New colors
  blue0: 'var(--brand-color-blue-0)',
  blue1: 'var(--brand-color-blue-1)',
  blue2: 'var(--brand-color-blue-2)',
  blue3: 'var(--brand-color-blue-3)',
  blue4: 'var(--brand-color-blue-4)',
  blue5: 'var(--brand-color-blue-5)',
  blue6: 'var(--brand-color-blue-6)',
  blue7: 'var(--brand-color-blue-7)',
  blue8: 'var(--brand-color-blue-8)',
  blue9: 'var(--brand-color-blue-9)',
  blue10: 'var(--brand-color-blue-10)',
  green0: 'var(--brand-color-green-0)',
  green1: 'var(--brand-color-green-1)',
  green2: 'var(--brand-color-green-2)',
  green3: 'var(--brand-color-green-3)',
  green4: 'var(--brand-color-green-4)',
  green5: 'var(--brand-color-green-5)',
  green6: 'var(--brand-color-green-6)',
  green7: 'var(--brand-color-green-7)',
  green8: 'var(--brand-color-green-8)',
  green9: 'var(--brand-color-green-9)',
  green10: 'var(--brand-color-green-10)',
  grey0: 'var(--brand-color-grey-0)',
  grey1: 'var(--brand-color-grey-1)',
  grey2: 'var(--brand-color-grey-2)',
  grey3: 'var(--brand-color-grey-3)',
  grey4: 'var(--brand-color-grey-4)',
  grey5: 'var(--brand-color-grey-5)',
  grey6: 'var(--brand-color-grey-6)',
  grey7: 'var(--brand-color-grey-7)',
  grey8: 'var(--brand-color-grey-8)',
  grey9: 'var(--brand-color-grey-9)',
  grey10: 'var(--brand-color-grey-10)',
  purple0: 'var(--brand-color-purple-0)',
  purple1: 'var(--brand-color-purple-1)',
  purple2: 'var(--brand-color-purple-2)',
  purple3: 'var(--brand-color-purple-3)',
  purple4: 'var(--brand-color-purple-4)',
  purple5: 'var(--brand-color-purple-5)',
  purple6: 'var(--brand-color-purple-6)',
  purple7: 'var(--brand-color-purple-7)',
  purple8: 'var(--brand-color-purple-8)',
  purple9: 'var(--brand-color-purple-9)',
  purple10: 'var(--brand-color-purple-10)',
  red0: 'var(--brand-color-red-0)',
  red1: 'var(--brand-color-red-1)',
  red2: 'var(--brand-color-red-2)',
  red3: 'var(--brand-color-red-3)',
  red4: 'var(--brand-color-red-4)',
  red5: 'var(--brand-color-red-5)',
  red6: 'var(--brand-color-red-6)',
  red7: 'var(--brand-color-red-7)',
  red8: 'var(--brand-color-red-8)',
  red9: 'var(--brand-color-red-9)',
  red10: 'var(--brand-color-red-10)',
  salmon0: 'var(--brand-color-salmon-0)',
  salmon1: 'var(--brand-color-salmon-1)',
  salmon2: 'var(--brand-color-salmon-2)',
  salmon3: 'var(--brand-color-salmon-3)',
  salmon4: 'var(--brand-color-salmon-4)',
  salmon5: 'var(--brand-color-salmon-5)',
  salmon6: 'var(--brand-color-salmon-6)',
  salmon7: 'var(--brand-color-salmon-7)',
  salmon8: 'var(--brand-color-salmon-8)',
  salmon9: 'var(--brand-color-salmon-9)',
  salmon10: 'var(--brand-color-salmon-10)',
  yellow0: 'var(--brand-color-yellow-0)',
  yellow1: 'var(--brand-color-yellow-1)',
  yellow2: 'var(--brand-color-yellow-2)',
  yellow3: 'var(--brand-color-yellow-3)',
  yellow4: 'var(--brand-color-yellow-4)',
  yellow5: 'var(--brand-color-yellow-5)',
  yellow6: 'var(--brand-color-yellow-6)',
  yellow7: 'var(--brand-color-yellow-7)',
  yellow8: 'var(--brand-color-yellow-8)',
  yellow9: 'var(--brand-color-yellow-9)',
  yellow10: 'var(--brand-color-yellow-10)',
};

export const colorNames = Object.keys(colorMap);
export const defaultColor = colorNames[0];

export const themeMap = {
  light: {
    background: 'var(--color-background-default)',
    text: 'var(--color-text-default)',
    className: themeStyles.light,
  },
  dark: {
    background: 'var(--color-background-inverted)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.dark,
  },
  blue: {
    background: 'var(--color-accent-default)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.blue,
  },
  teal: {
    background: 'var(--color-teal)',
    text: 'var(--color-text-default)',
    className: themeStyles.teal,
  },
  salmon: {
    background: 'var(--color-salmon)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.salmon,
  },
  yellow: {
    background: 'var(--color-yellow)',
    text: 'var(--color-text-default)',
    className: themeStyles.yellow,
  },
  purple: {
    background: 'var(--color-purple)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.purple,
  },
};
